import React, { useState } from 'react';
import Alert from '~components/common/Alert';
import Button from '~components/common/Button';
import Datenschutz from '~components/forms/elements/Datenschutz';
import Input from '~components/forms/elements/Input';
import { useAxios } from '~functions/hooks/useAxios';
import { StatusCodes } from '~types/requests/Statuscodes';
import { SipRegisterBody } from '~types/requests/SipRegister';
import { newsletter } from '~data/messages';

interface Props {
  listId: number;
  dataProtecionText: string;
}

const NewsletterForm: React.FC<Props> = ({ listId, dataProtecionText }) => {
  const [mail, setMail] = useState('');
  const [firstName, setFirstName] = useState('');

  const body: SipRegisterBody = {
    mail,
    firstName,
    list: listId
  };

  const { loading, error, data, operation } = useAxios('/registerSip', 'POST', body);

  const handleRequest = (e: React.FormEvent) => {
    e.preventDefault();
    operation();
  };

  if (data?.status === StatusCodes.CREATED) {
    return <Alert type="success" title="Erfolg!" message={`${newsletter.created}`} />;
  }

  if (data?.status === StatusCodes.NO_CONTENT) {
    return <Alert type="success" title="Erfolg!" message={`${newsletter.updated}`} />;
  }

  return (
    <form name="Interesse Workshop" onSubmit={(e) => handleRequest(e)} className="flex flex-col space-y-3">
      <div className="grid flex-1 grid-cols-2 gap-4">
        <Input type="text" id="name" name="Vorname" placeholder="Dein Name" onChange={(e) => setFirstName(e.target.value)} value={firstName} />
        <Input type="email" id="email" name="E-Mail" placeholder="Deine E-Mail" onChange={(e) => setMail(e.target.value)} value={mail} />
      </div>

      {error ? <Alert type="warning" title="Server-Fehler" message={typeof error === 'string' ? error : 'Etwas ist schief gelaufen'} /> : null}

      <Button submit text="Zum Newsletter anmelden" className="flex-shrink-0" loading={loading} />
      <Datenschutz praefix={dataProtecionText} />
    </form>
  );
};

export default NewsletterForm;
