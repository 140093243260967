// Packages
import React from 'react';
import { graphql, Link } from 'gatsby';
import parse from 'html-react-parser';

// Types
import type { SiteMetadata, Location } from '~types/Gatsby';
import { Blog } from '~types/Blog';

// Comppnents
import Layout from '~components/layouts/Default';
import PageHeader from '~components/sections/PageHeader';
import Container from '~components/common/Container';
import H1 from '~components/typography/H1';
import H2 from '~components/typography/H2';
import P from '~components/typography/P';
import SubTitle from '~components/typography/Subtitle';
import TestmonialSingle from '~components/sections/Testmonial';
import Grid from '~components/common/Grid';
import Image from '~components/common/Image';
import { CheckCircleIcon } from '@heroicons/react/outline';
import H3 from '~components/typography/H3';
import BlogCard from '~components/common/BlogCard';
import FaqItem from '~components/common/FaqItem';
import { LpAboData } from '~types/LpAbo';
import NewsletterForm from '~components/forms/newsletter/NewsletterForm';
import Button from '~components/common/Button';

interface DataProps {
  site: {
    siteMetadata: SiteMetadata;
  };
  landingpageAbo: LpAboData;
  allBlog: {
    nodes: Blog[];
  };
}

export default function Page({ data, location }: { data: DataProps; location: Location }) {
  const abo = data.landingpageAbo;

  const hasSomeContent = abo.contentTag || abo.contentSubheadline || abo.contentHeadline || abo.contentText || abo.remoteContentImage;
  const hasSomeFeature = abo.featureTag || abo.featureSubheadline || abo.featureHeadline || abo.featureText || abo.featureItems;
  const hasSomeCta = abo.ctaTag || abo.ctaText || abo.ctaHeadline;

  return (
    <Layout location={location} title={abo.seoTitle} desc={abo.seoDescription} minimalFooter={true}>
      <PageHeader image={abo.remoteHeaderImage} classNameImage="mx-auto" divider>
        {abo.headerTag && (
          <span className="mb-3 inline-flex items-center rounded-full bg-brand-gray p-1 pr-2 text-white hover:text-gray-200 sm:text-base lg:text-sm xl:text-base">
            <span className="rounded-full bg-brand-green px-3 py-0.5 text-sm font-semibold leading-5 text-brand-gray">{abo.headerTag}</span>
            <span className="ml-4 text-sm">Jetzt kostenfrei anmelden</span>
          </span>
        )}
        {abo?.headerHeadline && <H1 className={`${abo?.headerSubheadline ? 'mb-2' : 'mb-4'} text-brand-gray`}>{abo.headerHeadline}</H1>}
        {abo?.headerSubheadline && <H2 className="text-brand-green` mb-4">{abo.headerSubheadline}</H2>}
        {abo?.headerText && <div className="prose">{parse(abo.headerText)}</div>}
        {abo?.headerButton?.Text && abo?.headerButton?.Link && (
          <div className="mt-10 sm:mt-12">
            <Button text={abo.headerButton.Text} to={abo.headerButton.Link} />
          </div>
        )}
        {abo.showNewsletter && abo.dataProtecionText && (
          <div className="mt-10 sm:mt-12">
            <NewsletterForm listId={abo.listId} dataProtecionText={abo.dataProtecionText} />
          </div>
        )}
      </PageHeader>
      {hasSomeContent && (
        <Container>
          <Grid cols={2}>
            {abo.contentImageToTheLeft && abo?.remoteContentImage && <Image imageData={abo.remoteContentImage} alt={abo.contentHeadline + ' Bild'} />}
            <div>
              {abo?.contentTag && <SubTitle className="mb-2">{abo.contentTag}</SubTitle>}
              {abo?.contentHeadline && <H2 className={`${abo?.contentSubheadline ? 'mb-2' : 'mb-4'} text-brand-gray`}>{abo.contentHeadline}</H2>}

              {abo?.contentSubheadline && <H3 className="mb-4">{abo.contentSubheadline}</H3>}
              {abo?.contentText && <div className="prose">{parse(abo.contentText)}</div>}

              {abo?.contentButtons && abo.contentButtons.length > 0 && (
                <div className="mt-8 flex flex-wrap justify-center gap-3">
                  {abo.contentButtons.map((button) => (
                    <Button key={button.value.Link} to={button.value.Link} target="_blank" rel="noopener noreferrer">
                      {button.value.Text}
                    </Button>
                  ))}
                </div>
              )}
            </div>

            {!abo.contentImageToTheLeft && abo?.remoteContentImage && <Image imageData={abo.remoteContentImage} alt={abo.contentHeadline + ' Bild'} />}
          </Grid>
        </Container>
      )}

      {hasSomeFeature && (
        <Container>
          <div className="mx-auto max-w-2xl text-center">
            {abo?.featureTag && <SubTitle className="mb-2">{abo.featureTag}</SubTitle>}
            {abo?.featureHeadline && <H2 className={`${abo?.featureSubheadline ? 'mb-2' : 'mb-4'} text-brand-gray`}>{abo.featureHeadline}</H2>}

            {abo?.featureSubheadline && <H3 className="mb-4">{abo.featureSubheadline}</H3>}
            {abo?.featureText && <P className="mb-3">{abo.featureText}</P>}
          </div>

          {abo.featureItems && abo.featureItems.length > 0 && (
            <div className="mt-12">
              <Grid cols={3}>
                {abo.featureItems.map((feature) => (
                  <div className="pt-6 text-center" key={feature.value.Headline}>
                    <div className="flow-root rounded-lg bg-gray-50 px-6 pb-8">
                      <div className="-mt-6">
                        <div className="mb-8">
                          <span className="inline-flex items-center justify-center rounded-md bg-brand-green p-3 shadow-lg">
                            <CheckCircleIcon className="h-6 w-6" />
                          </span>
                        </div>
                        <H3 as="p" className="mb-5">
                          {feature.value.Headline}
                        </H3>
                        <P>{feature.value.Text}</P>
                      </div>
                    </div>
                  </div>
                ))}
              </Grid>
            </div>
          )}
          {abo?.featureButton?.Text && abo?.featureButton?.Link && (
            <div className="mt-10 text-center sm:mt-12">
              <Button text={abo.featureButton.Text} to={abo.featureButton.Link} />
            </div>
          )}
        </Container>
      )}

      {abo.remoteTestmonialImage && abo.testmonialText && abo.testmonialAuthor && <TestmonialSingle imageData={abo.remoteTestmonialImage} quote={abo.testmonialText} alt="Kundenstimme" author={abo.testmonialAuthor} />}

      <Container>
        <div className="mx-auto max-w-2xl text-center">
          <SubTitle className="mb-2">Ich will mehr erfahren</SubTitle>
          <H2 className="mb-4">Wertvolle Ressourcen</H2>
          <P className="mb-3">
            In unserem Blog haben wir einige Artikel zum Thema mentaler Gesundheit geschrieben, in dem du alle wesentlichen Informationen bekommst, die dich motivieren zu starten.{' '}
            <Link to="/blog/" className="underline">
              Zu allen Blogbeiträgen
            </Link>
            .
          </P>
        </div>
        <div className="mt-12">
          <Grid cols={3}>
            {data.allBlog.nodes.map((entry) => {
              return <BlogCard title={entry.titel} image={entry.remoteTitelbild} link={`/blog/${entry.slug_slug}/`} text={entry.einleitung} date={entry.datum} key={entry.id} fullText={entry.text} />;
            })}
          </Grid>
        </div>
      </Container>

      {abo.faq && abo.faq.length > 0 && (
        <Container>
          <Grid cols={2}>
            <div>
              <span className="mb-2 font-display text-xl font-bold uppercase text-brand-green">FAQ</span>
              <H2 className="mb-4 text-brand-gray">Du hast noch Fragen?</H2>
              <dl className="space-y-3">
                {abo.faq.map((faq) => {
                  return <FaqItem title={faq.value.Frage} text={<p>{faq.value.Antwort}</p>} key={faq.value.Antwort} />;
                })}
              </dl>
            </div>
            {abo.remoteFaqImage && <Image imageData={abo.remoteFaqImage} alt="" />}
          </Grid>
        </Container>
      )}

      {hasSomeCta && (
        <Container>
          <div className="mx-auto max-w-2xl text-center">
            {abo?.ctaTag && (
              <span className="mb-3 inline-flex items-center rounded-full bg-brand-gray p-1 pr-2 text-white hover:text-gray-200 sm:text-base lg:text-sm xl:text-base">
                <span className="rounded-full bg-brand-green px-3 py-0.5 text-sm font-semibold leading-5 text-brand-gray">{abo.ctaTag}</span>
                <span className="ml-4 text-sm">Jetzt kostenfrei anmelden</span>
              </span>
            )}

            {abo.ctaHeadline && <H1 className="mb-5">{abo.ctaHeadline}</H1>}
            {abo.ctaText && <P className="text-base sm:text-xl lg:text-lg xl:text-xl">{abo.ctaText}</P>}

            {abo?.ctaButton?.Text && abo?.ctaButton?.Link && (
              <div className="mt-10 sm:mt-12">
                <Button text={abo.ctaButton.Text} to={abo.ctaButton.Link} />
              </div>
            )}

            {abo.showNewsletter && abo.dataProtecionText && (
              <div className="mt-10 sm:mt-12">
                <NewsletterForm listId={abo.listId} dataProtecionText={abo.dataProtecionText} />
              </div>
            )}
          </div>
        </Container>
      )}
    </Layout>
  );
}

export const query = graphql`
  {
    landingpageAbo {
      seoTitle
      seoDescription
      showNewsletter
      dataProtecionText
      headerText
      headerButton {
        Text
        Link
      }
      testmonialText
      testmonialAuthor
      remoteTestmonialImage {
        childImageSharp {
          gatsbyImageData
        }
      }
      listId
      headerTag
      remoteHeaderImage {
        childImageSharp {
          gatsbyImageData
        }
      }
      headerHeadline
      headerSubheadline
      featureText
      featureTag
      featureItems {
        value {
          Headline
          Text
        }
      }
      featureHeadline
      featureSubheadline
      featureButton {
        Text
        Link
      }
      faq {
        value {
          Antwort
          Frage
        }
      }
      remoteFaqImage {
        childImageSharp {
          gatsbyImageData
        }
      }
      ctaText
      ctaTag
      ctaHeadline
      contentText
      contentTag
      ctaButton {
        Text
        Link
      }
      remoteContentImage {
        childImageSharp {
          gatsbyImageData
        }
      }
      contentHeadline
      contentSubheadline
      contentButtons {
        value {
          Link
          Text
        }
      }
      contentImageToTheLeft
    }

    allBlog(sort: { fields: datum, order: DESC }, limit: 6) {
      nodes {
        remoteTitelbild {
          childImageSharp {
            gatsbyImageData(quality: 80, placeholder: BLURRED)
          }
        }
        datum
        einleitung
        slug_slug
        text
        titel
        id
      }
    }
  }
`;
