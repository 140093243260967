import React from 'react';

interface Props {
  children: React.ReactNode;
  as?: string;
  className?: string;
}

const SubTitle: React.FC<Props> = ({ children, as = 'span', className }) => {
  const classes = `font-display font-bold uppercase text-brand-green ${className ? className : ''}`;
  return React.createElement(as, { className: classes }, children);
};

export default SubTitle;
