import { Link } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import React from 'react';
import P from '../typography/P';
import EstimatedReadingTime from './EstimatedReadingTime';

const BlogCard = ({ title, image, link, text, date, fullText }) => {
  const localeDate = new Date(date).toLocaleDateString('de-DE', {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric'
  });

  const imageRemote = getImage(image);

  return (
    <div className="flex flex-col overflow-hidden rounded-xl bg-white">
      <Link to={link}>
        <GatsbyImage image={imageRemote} alt={title} className="w-full" />
      </Link>

      <div className="flex flex-1 flex-col justify-between p-6">
        <div className="flex-1">
          <Link to={link} className="block">
            <P className="font-display text-lg font-bold" textColor="text-brand-gray">
              {title}
            </P>
            <P className="mt-3">
              <span className="text-sm">{text}</span>
            </P>
            <P className="mt-3 text-brand-green hover:text-brand-green-dark hover:underline">Weiterlesen</P>
          </Link>
        </div>
        {(date || fullText) && (
          <div className="mt-3 flex items-center">
            <div className="flex flex-col text-sm text-gray-500">
              {date && <time dateTime={date}>{localeDate}</time>}
              {fullText && (
                <span>
                  <EstimatedReadingTime text={fullText} />
                </span>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default BlogCard;
